import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Styles/style.scss";

//core components
import Loader from "../../components/Loader/Loader";

//containers:
import Blog from "../../containers/Blog/blog";

const LandingPage = () => {
    const [articles, setArticles] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_URL + "/v1/articles/bqfl9bbzhc")
            .then(function(response) {
                setArticles(response.data.articles);
                setIsLoading(false);
            })
            .catch(function(error) {
                setIsError(true);
                setErrorMessage(error.response.data.message);
                setIsLoading(false);
            });
    }, []);

    if (isError === true) {
        return (
            <div className="alert alert-danger my-3" role="alert">
                {errorMessage}
            </div>
        );
    }
    return <>{isLoading ? <Loader size="2" /> : <Blog articles={articles} />}</>;
};

export default LandingPage;
