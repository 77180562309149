import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Styles/style.scss";

import Loader from "../../components/Loader/Loader";

//section:
import Accordion from "./Sections/accordion/accordion";
import { Link } from "react-router-dom";

const Aside = () => {
    const [asideData, setAsideData] = useState({});
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get(process.env.PUBLIC_URL + "/data/rektorzy.json")
            .then(function (response) {
                setAsideData(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsError(true);
                setIsLoading(false);
            });

        // axios
        //     .get(process.env.REACT_APP_API_URL + "/v1/menu/b04i4dsgzp")
        //     .then(function(response) {
        //         setAsideKonferencje(response.data);
        //         setIsLoading(false);
        //     })
        //     .catch(function(error) {
        //         setIsError(true);
        //         setIsLoading(false);
        //     });
    }, []);

    if (isError === true) {
        return (
            <div className="alert alert-danger my-3" role="alert">
                Wystąpił błąd.
            </div>
        );
    }
    return (
        <>
            {isLoading ? (
                <Loader size="2" />
            ) : (
                <aside>
                    <div className="mb-3">
                        <h3 className="aside__heading text-center f-150 py-2 px-1">
                            Wybory na kadencję <br /> 2020-2024
                        </h3>
                        {asideData && <Accordion items={asideData} />}
                    </div>

                    <div className="mb-3">
                        <Link to="/article/rektorzy-w-kadencjach-2002-2024">
                            <p className="aside__heading h3 text-center f-125 py-2 px-2">
                                Rektorzy w kadencjach <br /> 2002-2024
                            </p>
                        </Link>
                    </div>

                    {/* {asideKonferencje && (
                        <div className="mb-3">
                            <h3 className="aside__heading text-center f-100 py-2">{asideKonferencje.name}</h3>
                            {asideKonferencje.items && (
                                <ul className="info__list">
                                    {asideKonferencje.items.map((e, index) => (
                                        <li key={index}>
                                            <a className="info__link f-80" target={e.target} rel="noopener noreferrer" href={e.url}>
                                                {e.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )} */}
                </aside>
            )}
        </>
    );
};

export default Aside;
