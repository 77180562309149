import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

//core components
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import PerspektywyLogo from "../assets/img/perspektywy_logo.png";

// Sections for this page
import AppFooter from "../components/Footer/AppFooter";
import Aside from "../containers/Aside/Aside";

// Views for this page
import HomePage from "./HomePage/HomePage";
import ArticlePage from "./ArticlePage/ArticlePage";

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <div className="AppContent container">
                    <header className="homePage__header d-flex justify-content-between flex-column flex-md-row text-center px-3 py-4">
                        <a href="https://perspektywy.pl" target="_blank" className="mb-3 mb-md-0" rel="noopener noreferrer">
                            <img src={PerspektywyLogo} alt="Perspektywy" />
                        </a>
                        <Link to="/" className="ml-0 ml-md-3">
                            <h1>
                                Wybory <span className="font-weight-bold">Rektorów 2020</span>
                            </h1>
                        </Link>
                    </header>
                    <hr />
                    <div className="row">
                        <div className="col-lg-9">
                            <main>
                                <Switch>
                                    <Route path="/" exact component={HomePage} />
                                    <Route path="/article/:slug" component={ArticlePage} />
                                </Switch>
                            </main>
                        </div>
                        <div className="col-lg-3">
                            <Aside />
                        </div>
                    </div>
                </div>
                <AppFooter />
            </div>
        </Router>
    );
};

export default App;
